<template>
  <div class="mostContainer">
    <div class="bodyArea">
      <img src="./images/404.png">
      <div class="title">
        <h2>页面走丢了...</h2>
        <h4>非常抱歉，您请求的页面不存在！</h4>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'index'
}
</script>

<style scoped lang="scss">
@import "./css/404index.css";
body{
  background-color: aliceblue;
}
</style>
